import React from "react";
// Customizable Area Start
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import parse, { Element, domToReact } from 'html-react-parser';

import { illustration1Img, ownerAvatarImg } from "./assets";
import AboutUsController, { Props } from "./AboutUsController.web";
import { UnauthPageWrapper } from "./LandingPage.web";
// Customizable Area End

export default class AboutUs extends AboutUsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <UnauthPageWrapper navigationData={this.state.sections[this.state.sections.length - 2]} newsletterData={this.state.sections[this.state.sections.length - 1]} onNavigate={screen => this.naviagteTo(screen)}>
        {this.state.sections.length > 0 && 
          <CenterFlexCol sx={{ gap: "33px", mt: "156px", mb: 7 }}>
            {this.state.sections[0] &&
              <>
                <CenterFlexCol>
                  <Box sx={{ padding: "10px" }}>
                    <SectionHeading>{parse(this.state.sections[0].term)}</SectionHeading>
                  </Box>
                  <img src={illustration1Img} alt="Illustration 1" />
                </CenterFlexCol>
                <CenterFlexCol
                  sx={{
                    padding: "10px 130px",
                    gap: "10px",
                    color: "#586554",
                    textAlign: "center",
                  }}
                >
                  {parse(this.state.sections[0].definition, {
                    replace(domNode) {
                      if (domNode instanceof Element && domNode.name === 'p') {
                        return <MdText>
                          {domToReact(domNode.children)}
                        </MdText>
                      }
                    },
                  })}
                </CenterFlexCol>
              </>}
            {this.state.sections[1] && 
              <CenterFlexCol sx={{ paddingX: "130px", gap: 2.5 }}>
                <Box sx={{ padding: "10px" }}>
                  <SectionHeading>{parse(this.state.sections[1].term)}</SectionHeading>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "13px" }}>
                  <BaseText
                    sx={{ color: "#343C32", fontSize: "24px", fontWeight: "700" }}
                  >
                    Kevin Stuart
                  </BaseText>
                  <Box sx={{ color: "#586554", "& p": { marginBottom: "13px" } }}>
                    <img
                      src={ownerAvatarImg}
                      alt="Owner Avatar"
                      style={{ float: "right", marginLeft: "13px" }}
                    />
                    <FlexCol sx={{ gap: '13px' }}>
                      {parse(this.state.sections[1].definition, {
                        replace(domNode) {
                          if (domNode instanceof Element && domNode.name === 'p') {
                            return <MdText>
                              {domToReact(domNode.children)}
                            </MdText>
                          }
                        },
                      })}
                    </FlexCol>
                  </Box>
                </Box>
              </CenterFlexCol>}
            {this.state.sections[2] && this.state.sections[2].children &&
              <CenterFlexCol sx={{ paddingX: "130px", gap: 2.5 }}>
                <Box sx={{ padding: "10px" }}>
                  <SectionHeading>{parse(this.state.sections[2].term)}</SectionHeading>
                </Box>
                <FlexCol sx={{ gap: 5, color: "#586554" }}>
                  {this.state.sections[2]?.children?.map((purpose, index) => (
                    <FlexCol key={index} sx={{ gap: "13px" }}>
                      {parse(purpose.definition, {
                        replace(domNode) {
                          if (domNode instanceof Element && domNode.name === 'h5') {
                            return <BaseText sx={{ fontSize: "24px", fontWeight: "700" }}>
                              {domToReact(domNode.children)}
                            </BaseText>
                          }
                          if (domNode instanceof Element && domNode.name === 'p') {
                            return <MdText>
                              {domToReact(domNode.children)}
                            </MdText>
                          }
                        },
                      })}
                    </FlexCol>
                  ))}
                </FlexCol>
              </CenterFlexCol>}
          </CenterFlexCol>
        }
      </UnauthPageWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const FlexCol = styled(Box)({
  display: "flex",
  flexDirection: "column",
});
const CenterFlexCol = styled(FlexCol)({
  alignItems: "center",
});
const BaseText = styled(Box)({
  fontFamily: "Lato",
  "& p": {
    margin: 0
  }
});
const MdText = styled(BaseText)({
  fontSize: "20px",
  fontWeight: "400",
});
const SectionHeading = styled(BaseText)({
  fontWeight: "700",
  lineHeight: "32px",
  fontSize: "24px",
  color: "#343C32",
  textAlign: "center",
  "& span": {
    fontSize: "36px",
    color: "#94CE81",
  },
});
// Customizable Area End
