import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import parse, { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';

import ContentBreadcrumbs from './ContentBreadcrumb.web';
import OverviewSection from './OverviewSection.web';
import PlainTextItem from './PlanTextItem.web';
import ItemWithImage from './ItemWithImage.web';
import { OverviewAnnotation } from './MethodOverviewSection.web';
import { CustomSectionContainer, SectionListLayout, SubcatContainer } from './CustomContainer.web';
import { ISectionData } from './SymptomARD.web';

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

function UnderstandingNonOpioid({ data, onNavigate }: { data: IContentData, onNavigate: (to: string) => void }) {
  const { attributes } = data;
  const { title, terms } = attributes;

  const parsingOptions: HTMLReactParserOptions = {
    replace(domNode) {
      if (domNode instanceof Element && domNode.name === 'li') {
        return (
          <PlainTextItem>{domToReact(domNode.children, parsingOptions)}</PlainTextItem>
        )
      }
      if (domNode instanceof Element && domNode.name === 'ul') {
        return (
          <>{domToReact(domNode.children, parsingOptions)}</>
        )
      }
    },
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  return (
    <>
      <ContentBreadcrumbs stacks='Dashboard/Medications/Pain/Non-Opioid Pain Meds' onNavigate={onNavigate} />
      <SectionListLayout styles={{ backgroundColor: { xs: '#F2F4F1', md: 'transparent' } }}>
        <OverviewSection 
          images={title ? title?.images.map(image => image.url) : []} 
          name={title && parse(title?.title)} 
          shortDesc={title && title?.desc && title?.desc !== '' && parse(title?.desc)}
          annotation={sectionList.length > 0 &&
            <OverviewAnnotation 
              title={parse(sectionList[0].name)} 
              image={sectionList[0].images.length > 0 ? sectionList[0].images[0].url : ''}
              content={parse(sectionList[0].definition, parsingOptions)}
              titleStyle={{ mb: { xs: 2.5 } }}
              contentStyle={{ gap: { xs: 2 } }}
            />}
        />

        <CustomSectionContainer variant="dark" contentStyle={{ gap: 5 }}>
          {sectionList
            .slice(1)
            .filter(section => section.id)
            .map((detail, index) => (
              <Box key={index}>
                <OverviewAnnotation 
                  title={parse(detail.name)}
                  image={detail.images.length > 0 ? detail.images[0]?.url : ''}
                  content={parse(detail.definition, parsingOptions)}
                  sx={{ flexWrap: { xs: 'wrap', lg: 'nowrap' } }}
                  titleStyle={{ color: 'white' }}
                  contentStyle={{ gap: 1.5 }}
                />
                {index === 0 && <Divider sx={{ mt: 5, backgroundColor: '#586554' }} />}
              </Box>
          ))}
        </CustomSectionContainer>
        
        <CustomSectionContainer 
          containerStyle={{ paddingTop: 0, paddingBottom: { xs: 2.5, lg: 0 } }} 
          contentStyle={{ flexDirection: 'row', flexWrap: 'wrap' }} 
          variant="light"
        >
          {sectionList
            .filter(section => section?.children && section?.children.length > 0)
            .map((section, index) => 
              <SubcatContainer
                key={index}
                containerStyle={{
                  py: { xs: 2.5, md: 5 },
                  marginTop: 0,
                  marginBottom: 0,
                  borderRadius: '8px', padding: '40px 20px', 
                  backgroundColor: index === 0 ? '#F2F4F1' : '#FEF2F2',
                  border: index !== 0 ? '2px dashed #F87171' : ''
                }}
                withHeading
                heading={parse(section.name)}
                headingStyle={{ textAlign: 'center' }}
              >
                 <Grid container spacing={2.5}>
                  {section?.children && section?.children.map((child, index) => (
                    <>
                      <Grid key={`lg-${index}`} item xs={12} sx={{ display: { xs: 'none', lg: 'block'}  }}>
                        <ItemWithImage image={child?.images[0]?.url || ''} text={parse(child?.definition)} />
                      </Grid>
                      <Grid key={`xs-${index}`} item xs={12} sx={{ display: { xs: 'block', lg: 'none'}  }}>
                        <ItemWithImage image={child?.images[0]?.url || ''} text={parse(child?.definition)} imageSize={60} />
                      </Grid>
                    </>
                  ))}
                </Grid>
              </SubcatContainer>)}
        </CustomSectionContainer>
      </SectionListLayout>
    </>
  );
}

export default UnderstandingNonOpioid;