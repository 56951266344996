import React, { ReactNode } from 'react';
import { Grid, Box } from '@mui/material';
import { styled, SxProps } from '@mui/system';

import ReactHtmlParser from './ReactHtmlParser';

const Container = styled(Grid)({
  width: '100%',
  maxWidth: '100%',
  padding: '10px',
  display: 'inline-flex',
  gap: '20px',
  alignItems: 'center',
  background: 'white',
  borderRadius: '8px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#D6DCD5',
  textAlign: 'left'
});

const Image = styled('img')({
  maxWidth: '120px', 
  maxHeight: '120px',
  width: '80px', 
  height: '80px',
  objectFit: 'contain'
});

const BaseTextContainer = styled(Box)({
  fontWeight: '400',
  fontFamily: 'Lato',
  color: '#343C32',
  lineHeight: '26.4px',
  '& p': {
    margin: 0
  }
});

type IItemWithImageProps = {
  image: string;
  imageSize?: number;
  text: string[] | ReactNode;
  style?: SxProps;
  textStyle?: SxProps;
}

const ItemWithImage = (props: IItemWithImageProps) => (
  <Container sx={props.imageSize && props.imageSize === 24 ? { px: 2.5, height: '120px', ...props.style } : {...props.style }}>
    <Image 
      src={props.image} 
      alt="Desc Image" 
      style={props?.imageSize ? { width: `${props.imageSize}px`, height: `${props.imageSize}px` } : undefined}
    />
    {React.Children.count(props.text) === 1 ?
       <Box sx={{height: '100%', width: '100%' }}>
          {props.text}
      </Box> :
      <Box sx={{ maxHeight: props.imageSize || '80px', width: '100%', overflow: 'auto', ...props.textStyle }}>
        <BaseTextContainer sx={{ fontSize: { xs: '16px', lg: '22px' } }}>
          {typeof props.text === 'string' ? ReactHtmlParser(props.text) : props.text}
        </BaseTextContainer>
      </Box>
    }
   
  </Container>
);

export default ItemWithImage;
