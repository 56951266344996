import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { extractTextNodeContent } from "../../../components/src/ReactHtmlParser";

import { ITermProps } from "./LandingPageController.web";

export const purposes = [
  {
    title: "Our Mission",
    description:
      "To enhance the quality of hospice care, we provide Informal Caregivers and patients with a user-friendly and informative web-based resource. Information is often complex and challenging to understand, especially when caregivers and patients are stressed. We believe reliable, usable, and easily accessible information is vital for hospice caregivers.",
  },
  {
    title: "Our Vision",
    description:
      "To become the leading information platform for Informal Caregivers of patients in hospice care. Our goal is to improve caregiver satisfaction, patient outcomes, and improved workflow for the Hospice Team.",
  },
  {
    title: "Our Values",
    description:
      "Our primary concern is for the patient at their end of life and for the caregiver doing their best their best to help their loved one. We value and respect the mission of the Hospice Team and do our best to support their efforts.",
  },
];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  screenId: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  sections: ITermProps[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  screenId: string;
  // Customizable Area End
}

// Customizable Area Start

// Customizable Area End

export default class AboutUsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getContentApiCallId: string = "";
  getVideoMediaApiCallId: string = "";
  getImageMediaApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      loading: false,
      sections: [],
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const errorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (errorMessage) {
      this.setState({ loading: true });
      this.parseApiCatchErrorResponse(errorMessage);
      return;
    }

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const contentData = responseJson.data;
    const attributes =
      (contentData && contentData.length > 0 && contentData[0]?.attributes) ||
      {};
    if (attributes?.terms) {
      attributes?.terms.sort(
        (prev: ITermProps, next: ITermProps) => prev?.id - next?.id
      );
      const termGroups: { [key: string]: ITermProps[] } = {};
      attributes?.terms.forEach((each: ITermProps) => {
        const key = extractTextNodeContent(each.term);
        if (!termGroups[key]) {
          termGroups[key] = [];
        }
        termGroups[key].push(each);
      });
      const sectionList = Object.entries(termGroups).map((classifiedGroup) => {
        if (classifiedGroup[1].length > 1) {
          return {
            id: classifiedGroup[1][0].id,
            term: classifiedGroup[1][0].term,
            definition: "",
            images: [],
            children: classifiedGroup[1].map((group) => {
              return { definition: group.definition, images: group.images };
            }),
          };
        }
        return {
          id: classifiedGroup[1][0].id,
          term: classifiedGroup[1][0].term,
          definition: classifiedGroup[1][0].definition,
          images: classifiedGroup[1][0].images,
        };
      });
      this.setState({ sections: sectionList });
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.fetchAboutUsData();
  }

  fetchAboutUsData() {
    const header = { "Content-Type": configJSON.exampleApiContentType };

    const fetchingDataReq = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    fetchingDataReq.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchingAboutUsApiEndPoint
    );
    fetchingDataReq.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    fetchingDataReq.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(fetchingDataReq.id, fetchingDataReq);
  }

  naviagteTo(screen: string) {
    const navigateMessage: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    navigateMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    if (screen === "PrivacyPolicy") {
      navigateMessage.addData(
        getName(MessageEnum.TermsAndConditionsTypeMessage),
        "Privacy Policy"
      );
      this.send(navigateMessage);
      return;
    }
    if (screen === "TermsConditions") {
      navigateMessage.addData(
        getName(MessageEnum.TermsAndConditionsTypeMessage),
        "Terms and Conditions"
      );
      this.send(navigateMessage);
      return;
    }
    this.props.navigation.navigate(screen);
  }
  // Customizable Area End
}
