import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import  {  createRef } from 'react';
import {  FormikErrors, FormikProps } from 'formik';
interface ResponseJson {
  meta: {
    token: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  loading: boolean
  isCaregiverLogin: boolean
  phoneNumber: string
  phoneNumberError: string | null
  isOpenModalInformation: boolean
  errorMessage:string | null
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  apiPhoneLoginCallId: string = "";
  apiCheckSubscriptionCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  formikRef = createRef<FormikProps< { email: string; password: string;  }>>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      loading:false,
      isCaregiverLogin: false,
      phoneNumber: "",
      phoneNumberError: null,
      isOpenModalInformation: false,
      errorMessage: null
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    this.formikRef = createRef();
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };


  setRememberMe = () => {
    this.setState({ checkedRememberMe: !this.state.checkedRememberMe });
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
      this.handleGetSavedInfo(message)
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        this.handleResponseAPIMessage(message)
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleResponseAPIMessage = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if(errorReponse){
      this.parseApiCatchErrorResponse(errorReponse);
      return
    }
    if (apiRequestCallId === this.apiEmailLoginCallId) {
      this.setState({loading: false})
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        this.saveLoggedInUserData(responseJson);
        this.sendLoginSuccessMessage();
        localStorage.setItem("current_role", 'hospice')
        this.handleCheckSubscription(responseJson.meta.token)
        
      } else {
        responseJson.errors.map((error: FormikErrors<{ email: string; password: string; }>) => {
          const currentFormikRef = this.formikRef.current!;
          currentFormikRef.setErrors(error);
        })
      }
    }
    if (apiRequestCallId === this.apiCheckSubscriptionCallId) {
      this.setState({loading: false})
      if (responseJson) {
        if(responseJson.status === "paid"){
          localStorage.setItem("user_status", 'paid')
          this.openDashboardPage();
        }else{
          this.props.navigation.replace("Subscriptionbilling2")
        }
      }
    }
    this.handlePhoneLoginFlow(message)
  }
  handlePhoneLoginFlow = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.apiPhoneLoginCallId) {
      this.setState({loading: false})
      if (responseJson && responseJson.data) {
        localStorage.setItem("user_status", 'paid')
        localStorage.setItem("caregiver_data", JSON.stringify(responseJson.data))
        localStorage.setItem("token", responseJson.data.attributes.session_token)
        localStorage.setItem("current_role", 'caregiver')
        this.openCatalogueScreen();
      } else {
        this.setState({
          phoneNumberError: "This phone number is not associated with an active patient"
        })
      }
  }
  }
  openCatalogueScreen = () => {
    this.props.navigation.replace("Catalogue")
  }
  handleGetSavedInfo = (message: Message) => {
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      if (userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });
      }
    }
  }
  sendLoginFailMessage() {
    const messageLogin: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(messageLogin);
  }

  sendLoginSuccessMessage() {
    const messageLogin: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    messageLogin.addData(getName(MessageEnum.LoginUserName), this.state.email);
    messageLogin.addData(getName(MessageEnum.LoginPassword), this.state.password);
    messageLogin.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(messageLogin);
  }

  saveLoggedInUserData = (responseJson: ResponseJson) => {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const messageLogin: Message = new Message(getName(MessageEnum.SessionSaveMessage));
      localStorage.setItem("user_data", JSON.stringify(responseJson))
      localStorage.setItem("token", responseJson.meta.token)
      localStorage.setItem("current_role", 'hospice')
      messageLogin.addData(
        getName(MessageEnum.AuthTokenEmailMessage),
        JSON.stringify(responseJson)
      );
      messageLogin.addData(
        getName(MessageEnum.AuthTokenDataMessage),
        responseJson.meta.token
      );

      this.send(messageLogin);
    }
  }

  openDashboardPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    this.props.navigation.replace("Dashboard")
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToSignUp = () => {
    const messageNavigate: Message = new Message(getName(MessageEnum.NavigateEmailSignUpMessage));
    messageNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messageNavigate);
  }

  goToForgotPassword = () => {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const messageNavigate: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    messageNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    messageNavigate.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(messageNavigate);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  handleLogin = (email: string, password: string) => {
    this.setState({loading: true})
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
    const postBody = {
      type: "email_account",
      attributes: {
        email, password
      },
    };

    const httpBody = {
      data: postBody,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.newLoginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  goToPrivacyPolicy = () => {
    const messagePP: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    messagePP.addData(getName(MessageEnum.TermsAndConditionsTypeMessage), "Privacy Policy");
    messagePP.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messagePP);
  }

  goToTermsAndCondition = () => {
    const messageTAC: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    messageTAC.addData(getName(MessageEnum.TermsAndConditionsTypeMessage), "Terms and Conditions");
    messageTAC.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messageTAC);
  }
  callGetValidationApi() {
    this.setState({email: ""})
  }
  changeToCaregiverLogin = () => {
    this.setState({isCaregiverLogin: true})
  }
  changeToHospiceLogin = () => {
    this.setState({isCaregiverLogin: false})
  }
  handleCaregiverLogin = () => {
    this.setState({loading: true})
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const httpBody = {
      phone_number: this.state.phoneNumber,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPhoneLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.newCaregiverLoginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleCheckSubscription = (token: string) => {
    this.setState({loading: true})
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCheckSubscriptionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.checkSubscriptionAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleOpenInformationModal = () => {
    this.setState({
      isOpenModalInformation: true
    })
  }
  handleCloseInformationModal = () => {
    this.setState({
      isOpenModalInformation: false
    })
  }
  showModalError = (message: string) => {
    this.setState({
      errorMessage: message
    })
    setTimeout(() => {
      this.setState({
        errorMessage: null
      })
    }, 3000);
  }
  // Customizable Area End
}
