import React, { FC, ReactNode } from "react";
// Customizable Area Start
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";

import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { styled } from "@mui/system";
import parse, { HTMLReactParserOptions, Element, domToReact } from 'html-react-parser';
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import LandingPageController, {
  ICardProps,
  ITermProps,
  Props,
  quickLinks,
} from "./LandingPageController.web";
import {
  adPosterImg,
  bannerImg,
  illustrationImg,
  locationIcon,
  logoImg,
  mailIcon,
  mailWhiteIcon,
  phoneIcon,
} from "./assets";
import ReactHtmlParser from "../../../components/src/ReactHtmlParser";

SwiperCore.use([Autoplay]);

const options: HTMLReactParserOptions = {
  replace(domNode) {
    if (domNode instanceof Element && domNode.name === 'h5') {
      return <BaseText
        sx={{ fontSize: "24px", fontWeight: "700", color: "#343C32" }}
      >
        {domToReact(domNode.children)}
      </BaseText>
    }
    if (domNode instanceof Element && domNode.name === 'p') {
      return <SmText>
        {domToReact(domNode.children)}
      </SmText>
    }
  }
};

const titleWithSubOptions: HTMLReactParserOptions = {
  replace(domNode) {
    if (domNode instanceof Element && domNode.name === 'h4') {
      return <XlText color="#343C32" mb={2.5} sx={{ fontSize: "36px" }}>
        {domToReact(domNode.children)}
      </XlText>
    }
    if (domNode instanceof Element && domNode.name === 'p') {
      return <MdText color="#586554">
        {domToReact(domNode.children)}
      </MdText>
    }
  },
};

export const UnauthPageWrapper = ({
  navigationData,
  newsletterData,
  onNavigate,
  children,
}: {
  navigationData?: ITermProps;
  newsletterData?: ITermProps;
  onNavigate: (screen: string) => void;
  children: ReactNode;
}) => (
  <Body>
    <CustomHeader>
      <Link underline="none" href="/LandingPage" sx={{ paddingLeft: { xs: "16px", lg: "130px" } }}>
        <img src={logoImg} alt="Partner for Care Logo" />
      </Link>
      <HeaderRight>
        <ContactInfo href="mailto:support@partnerforcare.com" underline="hover" target="_blank" rel="noopener">
          <CustomIcon src={mailIcon} />
          <XsText>support@partnerforcare.com</XsText>
        </ContactInfo>
        <Box sx={{ padding: "8px 130px" }}>
          <Navigation>
            <NavigationItem
              data-test-id="btn-about-us"
              sx={{ textTransform: "capitalize" }}
              onClick={() => onNavigate("AboutUs")}
            >
              about us
            </NavigationItem>
            <NavigationItem
              data-test-id="btn-contact-us"
              sx={{ textTransform: "capitalize" }}
              onClick={() => onNavigate("Contactus")}
            >
              contact us
            </NavigationItem>
            <NavigationItem
              data-test-id="btn-login"
              variant="contained"
              sx={{
                textTransform: "capitalize",
                backgroundColor: "#71816B",
                padding: "4px 30px",
                border: "none",
                ":hover": {
                  backgroundColor: "#586554",
                  border: "none",
                },
              }}
              onClick={() => onNavigate("EmailAccountLoginBlock")}
            >
              login
            </NavigationItem>
          </Navigation>
        </Box>
      </HeaderRight>
    </CustomHeader>
    {children}
    {navigationData && <SectionWithBg
      sx={{
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundBlendMode: "overlay",
      }}
    >
      <CenterFlexCol sx={{ gap: 2.5, zIndex: 999, width: '65%', margin: 'auto' }}>
        <CenterFlexCol>
          <img src={illustrationImg} alt="How to access" />
          {parse(navigationData.term, titleWithSubOptions)}
        </CenterFlexCol>
        <Box sx={{ display: "flex", gap: "20px" }}>
          {navigationData.children && navigationData.children?.length > 0 && navigationData.children.map((child, index) => (
            <DirectionCard key={index}>
              {parse(child.definition, {
                replace(domNode) {
                  if (domNode instanceof Element && domNode.name === 'h4') {
                    return <XlText color="#343C32" sx={{ mb: "2px", fontSize: "30px" }}>
                      {domToReact(domNode.children)}
                    </XlText>
                  }
                  if (domNode instanceof Element && domNode.name === 'p') {
                    return <LgText color="#586554" sx={{ minHeight: "96px" }}>
                      {domToReact(domNode.children)}
                    </LgText>
                  }
                }
              })}
              <NavigationItem
                data-test-id="btn-auth"
                variant="contained"
                sx={{
                  width: "248px",
                  textTransform: "capitalize",
                  backgroundColor: "#7C8E76",
                  padding: "10px 16px",
                  border: "none",
                  "&:hover": {
                    backgroundColor: "#586554",
                    border: "none",
                  },
                }}
                onClick={() => onNavigate(index % 2 === 0 ? "EmailAccountLoginBlock" : "EmailAccountRegistration")}
              >
                {index % 2 === 0 ? 'login' : 'register'}
              </NavigationItem>
            </DirectionCard>
          ))}
        </Box>
      </CenterFlexCol>
    </SectionWithBg>}
    {newsletterData && <Section
      sx={{
        marginY: "-80px",
        padding: "80px 0",
        gap: "12px",
        textAlign: "center",
      }}
    >
      <CenterFlexCol sx={{ width: '50%', marginX: 'auto' }}>
        <XlText color="#343C32" sx={{ fontSize: "36px" }}>
          {ReactHtmlParser(newsletterData.term)}
        </XlText>
        <MdText color="#343C32">
          {ReactHtmlParser(newsletterData.definition)}
        </MdText>
        <NavigationItem
          variant="contained"
          sx={{
            marginTop: 2.5,
            textTransform: "capitalize",
            backgroundColor: "#7C8E76",
            padding: "10px 16px",
            display: "flex",
            gap: 1,
            border: "none",
            "&:hover": {
              backgroundColor: "#586554",
              border: "none",
            },
          }}
          onClick={() => onNavigate("Contactus")}
        >
          <CustomIcon src={phoneIcon} />
          <XsText>contact us</XsText>
        </NavigationItem>
      </CenterFlexCol>
    </Section>}
    <CustomFooter data-test-id="footer-section">
      <Grid container mb="85px">
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              padding: "20px 40px",
              borderRight: "1px solid #7C8E7680",
              height: "100%",
            }}
          >
            <img src={logoImg} alt="Partner for Care Logo" />
            <SmText sx={{ mt: 2.5 }}>
              <b>Partner for Care</b> is your trusted companion, offering a
              comprehensive repository of resources and guidance to support you
              every step of the way in your caregiving journey. With our
              user-friendly platform, you can access vital information and
              assistance whenever you need it.
            </SmText>
          </Box>
        </Grid>
        <Grid item xs={12} md={3.5}>
          <Box
            sx={{
              padding: "20px 40px",
              borderRight: "1px solid #7C8E7680",
              height: "100%",
            }}
          >
            <BaseText fontSize={24} fontWeight="500">
              Quick Links
            </BaseText>
            <Links>
              {quickLinks.map((link, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", gap: "8px", alignItems: "center" }}
                >
                  <ChevronRight />
                  <NavigationItem
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => onNavigate(link.value)}
                    data-test-id={!!link?.testId && link?.testId}
                  >
                    {link.label}
                  </NavigationItem>
                </Box>
              ))}
            </Links>
          </Box>
        </Grid>
        <Grid item xs={12} md={3.5}>
          <Box sx={{ padding: "20px 40px", height: "100%" }}>
            <Links>
              <Box sx={{ display: "flex", gap: "16px" }}>
                <CustomIcon src={locationIcon} />
                <SmText fontWeight="500">
                  <b>Partner for Care</b>
                  <br />
                  15585 Monterey Street
                  <br />
                  Suite B<br />
                  Morgan Hill, CA 95037
                </SmText>
              </Box>
              <Box sx={{ display: "flex", gap: "16px" }}>
                <CustomIcon src={mailWhiteIcon} />
                <MailLink href="mailto:support@partnerforcare.com" underline="hover" target="_blank" rel="noopener">
                  support@partnerforcare.com
                </MailLink>
              </Box>
            </Links>
          </Box>
        </Grid>
      </Grid>
      <Box pt={2} sx={{ borderTop: "1px solid #7C8E7680" }}>
        <SmText fontWeight="600">
          © Copyright 2024 | <b>Partner for Care</b> | All Rights Reserved
        </SmText>
      </Box>
    </CustomFooter>
  </Body>
);
// Customizable Area End

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  renderServiceCard: FC<ICardProps> = ({ thumbnail, children }) => (
    <Card>
      <img
        src={thumbnail}
        style={{ width: "180px", height: "180px", objectFit: "contain" }}
      />
      <CenterFlexCol sx={{ gap: "13px" }}>{children}</CenterFlexCol>
    </Card>
  );
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <UnauthPageWrapper 
        onNavigate={(screen) => this.naviagteTo(screen)} 
        navigationData={this.state.navigationData}
        newsletterData={this.state.newsletterData}
      >
        <Box sx={{ position: "relative" }}>
            <img
              src={bannerImg}
              alt="Partner for Care Banner"
              style={{ width: "100%" }}
            />
            <Overview>
              <XlText sx={{ textTransform: "uppercase" }}>
                Because Every Goodbye Deserves Grace
              </XlText>
              <SmText>
                <b>Partner for Care </b>
                ensures that caregivers never feel alone as they tend to their
                loved ones in Hospice care. We offer a comprehensive resource hub
                with information and guidance for every step of the journey.
              </SmText>
            </Overview>
        </Box>
        {this.state.terms.length > 0 && <>
          {this.state.terms[0] && <Section sx={{ paddingX: "130px" }}>
            <SectionHeading>
              {ReactHtmlParser(this.state.terms[0]?.term)}
            </SectionHeading>
            <Box>
              <Box>
                <video
                  controls
                  poster={adPosterImg}
                  src={this.state.videoUrl}
                  style={{
                    float: "left",
                    marginRight: "20px",
                    borderRadius: "8px",
                    background: "rgba(0, 0, 0, 0.5)",
                    width: "430px",
                    height: "256px",
                  }}
            
                >
                  Your browser doesn’t support HTML5 video.
                </video>
              </Box>
              <Box sx={{ color: "#586554" }}>
                <MdText sx={{ "& p": { marginBottom: "8px" }}}>
                  {ReactHtmlParser(this.state.terms[0]?.definition)}
                </MdText>
              </Box>
            </Box>
          </Section>}
          {this.state.terms[1] && <Section>
            <SectionHeading>
              {ReactHtmlParser(this.state.terms[1]?.term)}
            </SectionHeading>
            <SwiperContainer>
              <Swiper
                slidesPerView={1}
                spaceBetween={3}
                breakpoints={{
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 6,
                  },
                  1280: {
                    slidesPerView: 3,
                    spaceBetween: 12,
                  },
                  1600: {
                    slidesPerView: 4,
                    spaceBetween: 12,
                  }
                }}
                centeredSlides
                loop
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                speed={1000}
              >
                {this.state.terms[1] && this.state.terms[1]?.children && this.state.terms[1]?.children.map((child, index) => {
                  const thumbnail = child.images[0];
                  const content = parse(child.definition, options);
                  return (
                    <SwiperSlide key={index}>
                      {this.renderServiceCard({ thumbnail: thumbnail.url, children: content })}
                    </SwiperSlide>
                )})}
              </Swiper>
            </SwiperContainer>
          </Section>}
          {this.state.terms[2] && <Section>
            <SectionHeading>
              {ReactHtmlParser(this.state.terms[2]?.term)}
            </SectionHeading>
            <BenefitContainer>
              {this.state.terms[2].children && this.state.terms[2].children.map((child, index) => {
                const parsingOptions: HTMLReactParserOptions = {
                  replace(domNode) {
                    if (domNode instanceof Element && domNode.name === 'h4') {
                      return <BenefitTitle>{domToReact(domNode.children)}</BenefitTitle>
                    }
                    if (domNode instanceof Element && domNode.name === 'li') {
                      return <li>
                        <LgText sx={{ color: "#586554" }}>
                          {domToReact(domNode.children)}
                        </LgText>
                    </li>
                    }
                  },
                };
                return (
                  <BenefitCard key={index} sx={{ ml: index === 0 ? '130px' : 0, mr: index === 1 ? '130px' : 0 }}>
                    {parse(child.definition, parsingOptions)}
                  </BenefitCard>
              )})}
            </BenefitContainer>
          </Section>}
        </>}
        
      </UnauthPageWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Body = styled(Box)({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: "80px",
  flex: 1,
  background: "#FFF",
  overflowX: "hidden",
});
const CustomHeader = styled("header")({
  width: "100%",
  background: "rgba(26, 34, 24, 0.8)",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "white",
  position: "absolute",
  top: 0,
  zIndex: 9999,
});
const HeaderRight = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "32px",
  alignItems: "flex-end",
});
const ContactInfo = styled(Link)({
  borderRadius: "0 0 0 50px",
  backgroundColor: "#F2F4F1",
  color: "#586554",
  padding: "6px 60px",
  display: "flex",
  gap: "8px",
});
const Navigation = styled(Box)({
  display: "flex",
  gap: "16px",
});
const NavigationItem = styled(Button)({
  fontFamily: "Lato",
  fontSize: "16px",
  fontWeight: "500",
  color: "white",
  padding: "4px 6px",
  borderBottom: "1px solid transparent",
  borderRadius: '8px',
  ":hover": {
    backgroundColor: "transparent",
    borderBottom: "1px solid #7C8E76",
  },
});
const Overview = styled(Box)({
  borderRadius: "8px",
  position: "absolute",
  bottom: "-35px",
  right: "130px",
  left: "825px",
  zIndex: 999,
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  background: "rgba(26, 34, 24, 0.9)",
  padding: "20px",
  "& p": { color: "white" },
});
const BaseText = styled(Typography)({
  fontFamily: "Lato",
  "& p": { margin: 0 },
});
const XsText = styled(BaseText)({
  fontSize: "16px",
  fontWeight: "500",
});
const SmText = styled(BaseText)({
  fontSize: "18px",
  fontWeight: "400",
});
const MdText = styled(BaseText)({
  fontSize: "20px",
  fontWeight: "400",
});
const LgText = styled(BaseText)({
  fontSize: "22px",
  fontWeight: "400",
});
const XlText = styled(BaseText)({
  fontSize: "32px",
  fontWeight: "700",
});
const CustomIcon = styled("img")({
  width: "24px",
  height: "24px",
  objectFit: "contain",
});
const Section = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "20px",
});
const SectionHeading = styled(BaseText)({
  fontWeight: "700",
  lineHeight: "32px",
  fontSize: "24px",
  color: "#343C32",
  textAlign: "center",
  "& span": {
    fontSize: "36px",
    color: "#94CE81",
  },

});
const CenterFlexCol = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});
const Card = styled(CenterFlexCol)({
  height: "94%",
  width: "384px",
  border: "1px solid rgba(148, 206, 129, 0.4)",
  borderRadius: "8px",
  padding: "12px 20px",
  gap: "20px",
  color: "#586554",
  textAlign: "center",
});
const SwiperContainer = styled('div')({
  ".swiper-slide": { height: "auto" },
  width: '100%',
});
const BenefitContainer = styled(Box)({
  display: "flex",
  gap: "20px",
  width: '100%',
  justifyContent: 'center'
});
const BenefitCard = styled(CenterFlexCol)({
  borderRadius: "8px",
  padding: "40px 20px",
  backgroundColor: "#FFF0E8",
  gap: "20px",
  flex: 1 
});
const BenefitTitle = styled(BaseText)({
  fontSize: "30px",
  fontWeight: "700",
  color: "#343C32",
});
const SectionWithBg = styled(Box)({
  padding: "38px 0",
  backgroundColor: '#FAFDFE',
  textAlign: "center",
});
const DirectionCard = styled(CenterFlexCol)({
  border: "2px dashed #94CE81",
  borderRadius: "8px",
  padding: "40px 20px",
  flexBasis: "100%",
  gap: "18px",
});
const CustomFooter = styled("footer")({
  width: "100%",
  background: "#343C32",
  padding: "60px",
  color: "white",
});
const Links = styled(Box)({
  marginTop: "20px",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});
const MailLink = styled(Link)({
  fontFamily: 'Lato',
  fontSize: "18px",
  fontWeight: "500",
  color: "white",
});
// Customizable Area End
