import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from '@mui/system';

import ContentBreadcrumbs from "./ContentBreadcrumb.web";
import ItemWithImage from "./ItemWithImage.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";
import { ISectionData } from "./SymptomARD.web";
import ReactHtmlParser, { extractTextNodeContent } from "./ReactHtmlParser";
import PlainTextItem from "./PlanTextItem.web";
import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const toiletManImage = require('./toilet_man.svg');
const howToTakeImage = require('./how_to_take.svg');
const whenItWorkImage = require('./when_they_work.svg');

interface TypesOpioidMedProps {
  data: IContentData, onNavigate: (to: string) => void 
}

export default function TypesOpioidMed({data, onNavigate }: TypesOpioidMedProps) {
  const renderArrowRight = (color: string) => {
    return <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.997666 19.0147C1.48767 19.5047 2.27767 19.5047 2.76767 19.0147L11.0777 10.7047C11.4677 10.3147 11.4677 9.68469 11.0777 9.29469L2.76767 0.984688C2.27767 0.494687 1.48767 0.494687 0.997666 0.984688C0.507666 1.47469 0.507666 2.26469 0.997666 2.75469L8.23767 10.0047L0.987666 17.2547C0.507666 17.7347 0.507666 18.5347 0.997666 19.0147Z" fill={color}/>
    </svg>
    
  }
  const { attributes } = data;
  const { title, terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  const renderList = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const listItems = Array.from(doc.querySelectorAll('li'));
    if (listItems.length > 0) {
      return listItems.map((item, index) => (<PlainTextItem key={index}>{ReactHtmlParser(item.innerHTML)}</PlainTextItem>))
    } 
    return ReactHtmlParser(htmlString);
  }

  const navbarStyles = {
    dash: {
      color: "#96A591",
      fontStyle: "Lato"
    },
  };
  const getSectionArray = (originArray: ISectionData[]) => {
    const tempOriginArray = [...originArray]
    return tempOriginArray.splice(0, 4)
  }
  return (
    <>
      <ContentBreadcrumbs stacks='Dashboard/Medications/Pain/Opioid Pain Meds/Types of Opioid Pain Meds' onNavigate={onNavigate} />

   <Container>
      <LatoText sx={{fontWeight: "700", fontSize: '26px', textAlign: 'center', padding: '10px', '& p': { margin: 0 }}}>
        {ReactHtmlParser(title.title)}
      </LatoText>
      <Grid  container spacing={2.5}>
      {sectionList.length > 3 && getSectionArray(sectionList).map((section, index) => {
        return (
          <Grid key={index} xs={12} md={6} item>
            <ItemContainer sx={{ color: '#343C32' }}>
                <LatoText style={{ fontWeight: "700", fontSize: 26, color: "#586554"}}>{ReactHtmlParser(section?.name)}</LatoText>
                {renderList(section.definition)}
            </ItemContainer>
      </Grid>)
      })}
   
      </Grid>
      {sectionList[4] && <ItemBottomContainer sx={{ color: '#343C32' }}>
       <img style={{width: 120, height: 120}} src={sectionList[4].images[0].url}/>
       <div style={{display: "flex", flexDirection: 'column', gap: 12}}>
       {renderList(sectionList[4].definition)}
        </div>
        </ItemBottomContainer>}
   </Container>
   </>
  );
}
const Container = styled(Box)({
  display: 'flex', flexDirection: 'column', rowGap: 20, paddingLeft: 130, paddingRight: 130,
  '@media (max-width: 1180px)' : {
    paddingLeft: 16, paddingRight: 16,
    }
});
const ItemBottomContainer = styled(Box)({
  display: 'flex',flex: 1,marginBottom: 30, flexDirection: 'row', background: "#FEF2F2", padding: 40, paddingLeft: 20, paddingRight: 20,columnGap: 20, borderRadius: 8, border: "2px dashed #F87171",
  '@media (max-width: 1180px)' : {
    flexDirection: 'column',
    rowGap: "35px"
    }
});
const ItemContainer = styled(Box)({
  height: 'calc(100% - 20px)', display: 'flex',flex: 1, flexDirection: 'column', background: "#F2F4F1", padding: 10, paddingLeft: 20, paddingRight: 20, gap: 16, borderRadius: 8,
  // '@media (max-width: 1180px)' : {
  //   height: '95%'
  //   }
});

const RowItemTop = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  columnGap: 10
});
const RowItem = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  columnGap: 10
});
const LatoText = styled(Typography)({
  fontFamily: "Lato",
  color: "#343C32",
  fontWeight: "400"
});
const DescriptionText = styled(Typography)({
  fontFamily: "Lato",
  color: "#343C32",
  fontWeight: "400",
  fontSize: 22,
});
const NavigationText = styled(Typography)({
  fontFamily: "Lato",
  color: "#96A591",
  fontWeight: "400",
  fontSize: 22,
});

